<template>
  <div>
    <el-dialog
      class="class-room-dialog"
      :title="`查看门锁信息(教室-${title})`"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="handleClose"
      width="680px"
    >
      <div class="font-right mb-1">
        <el-button type="primary" size="small" @click="showDialog()">新增门锁</el-button>
      </div>
      <el-table :data="lockList" border stripe height="400px">
        <el-table-column prop="roomName" label="锁名" width="120" />
        <el-table-column
          prop="lockFeatureCode"
          label="设备码"
          width="180"
        />
        <el-table-column prop="remarks" label="备注" />
        <el-table-column label="操作" width="150">
          <template v-slot="{row}">
            <el-button
              type="primary"
              size="mini"
              @click="showDialog(row.id)"
            >编辑
            </el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="del(deleteTeachRoom,row.id,`门锁-${row.roomName}(${row.lockFeatureCode})`)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="handleClose">关闭</el-button>
      </template>
    </el-dialog>
    <add-teach-room-lock ref="dialogRef" :visible.sync="visibleDialog"
                         v-if="visibleDialog" @on-close="handleLockClose" />
  </div>
</template>

<script>
import addTeachRoomLock from './addTeachRoomLock.vue'
import { getTeachRoomListQuery, deleteTeachRoom } from '@/api/teachRoom'
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'

export default {
  mixins: [dialog, tableView],
  components: {
    addTeachRoomLock
  },
  data () {
    return {
      parentInfo: {
        title: '',
        campusId: '',
        buildId: '',
        floorNo: '',
        classroomId: ''
      },
      updateFlag: false,
      tableQuery: {
        campusId: '',
        buildId: '',
        floorNo: ''
      },
      lockList: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderTable()
    })
  },
  methods: {
    deleteTeachRoom, // 删除api
    // 添加/编辑门锁
    showDialog (id) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.preTitle = id ? '编辑' : '添加'
        if (id) {
          this.$refs.dialogRef.id = id
        } else {
          Object.keys(this.parentInfo).forEach(key => {
            this.$refs.dialogRef.formData[key] = this.parentInfo[key]
          })
          this.$refs.dialogRef.setInitData()
        }
      })
    },
    // 查询教室列表
    renderTable () {
      this.tableQuery.classroomId = this.parentInfo.classroomId
      if (!this.tableQuery.classroomId) {
        this.$message.error('请先选择教室')
        this.close()
        return
      }
      getTeachRoomListQuery(this.tableQuery).then((res) => {
        this.lockList = res.data
      })
    },
    handleLockClose () {
      this.updateFlag = this.$refs.dialogRef.updateFlag
      this.renderTable()
    },
    handleClose () {
      // 门锁信息有更新,则关闭弹窗后请求刷新table
      if (this.updateFlag) {
        this.closed()
      } else { // 否则不刷琴
        this.close()
      }
    }
  }
}
</script>
