var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"class-room-dialog",attrs:{"title":(_vm.preTitle + "教室"),"visible":_vm.show,"close-on-click-modal":false,"before-close":_vm.cancel,"width":"540px"},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save('教室', _vm.saveClassroomList)}}},[_vm._v("确 定")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"所在校区","prop":"campusId"}},[_c('el-select',{on:{"change":_vm.handleTeachBuildList},model:{value:(_vm.formData.campusId),callback:function ($$v) {_vm.$set(_vm.formData, "campusId", $$v)},expression:"formData.campusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":campusName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"所在教学楼","prop":"buildId"}},[_c('el-select',{on:{"change":_vm.handleFloorList},model:{value:(_vm.formData.buildId),callback:function ($$v) {_vm.$set(_vm.formData, "buildId", $$v)},expression:"formData.buildId"}},_vm._l((_vm.teachBuildList),function(ref){
var label = ref.label;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":label,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"所在楼层","prop":"floorNo"}},[_c('el-select',{model:{value:(_vm.formData.floorNo),callback:function ($$v) {_vm.$set(_vm.formData, "floorNo", $$v)},expression:"formData.floorNo"}},_vm._l((_vm.floorList),function(ref){
var label = ref.label;
var floorNo = ref.floorNo;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":label,"value":floorNo}})}),1)],1),_c('el-form-item',{attrs:{"label":"新增教室命名","prop":"classroomName"}},[_c('el-input',{model:{value:(_vm.formData.classroomName),callback:function ($$v) {_vm.$set(_vm.formData, "classroomName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.classroomName"}})],1),_c('el-form-item',{attrs:{"label":"备注","prop":"remarks"}},[_c('el-input',{model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.remarks"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }