import request from '@/service/request'

// 获取教室列表
const getTeachRoomList = (params) => {
  return request.post('/app/teachRoom/page', params)
}

// 添加教室
const addTeachRoom = (params) => {
  return request.post('/app/teachRoom/save', params)
}

// 删除教室
const deleteTeachRoom = (params) => {
  return request.post('/app/teachRoom/del/' + params)
}

// 根据id获取教室信息
const getTeachRoomById = (params) => {
  return request.post('/app/teachRoom/getById/' + params)
}

// 添加查询教室
/**
 * @param {String} campusId 校区id
 */
const getTeachRoomListQuery = (params) => {
  return request.post('/app/teachRoom/list', params)
}

export {
  getTeachRoomList,
  addTeachRoom,
  deleteTeachRoom,
  getTeachRoomById,
  getTeachRoomListQuery
}
