<template>
  <el-dialog
    class="add-teach-room-dialog"
    :title="`${preTitle}门锁`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="680px"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="150px"
    >
      <el-form-item label="所在教学楼" prop="buildId">
        <el-select v-model="formData.buildId" disabled @change="handleBuild">
          <el-option
            v-for="{label, id} in teachBuildList"
            :label="label"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所在校区" prop="campusId">
        <el-select v-model="formData.campusId" disabled @change="handleCampus">
          <el-option
            v-for="{campusName, id} in campusList"
            :label="campusName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所在楼层" prop="floorNo">
        <el-input-number
          v-model="formData.floorNo"
          :min="1"
          :max="30"
          disabled
          @change="handleFloor" />
      </el-form-item>
      <el-form-item label="所在教室" prop="classroomId">
        <el-select ref="classRoomRef" v-model="formData.classroomId" :disabled="!classRoomList.length"
                   filterable @change="handleClassName">
          <el-option
            v-for="{id, classroomName} in classRoomList"
            :key="id"
            :value="id"
            :label="classroomName"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="门锁命名" prop="roomName">
        <el-input v-model.trim="formData.roomName" />
      </el-form-item>
      <el-form-item label="绑定锁的设备特征值" prop="lockFeatureCode">
        <el-input v-model.trim="formData.lockFeatureCode" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getTeachBuildTree } from '@/api/teachBuild'
import { addTeachRoom, getTeachRoomById } from '@/api/teachRoom'
import { getClassroomList } from '@/api/teachClassroom'
import dialog from '@/vue/mixins/dialog'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  mixins: [dialog, campusSchMajorClasses],
  data () {
    return {
      teachBuildList: [], // 教学楼list
      classRoomList: [], // 课室list
      formData: {
        buildId: '',
        campusId: '',
        floorNo: '',
        classroomId: '',
        roomName: '',
        classroomName: '',
        id: null,
        lockFeatureCode: '',
        remarks: ''
      },
      rules: {
        roomName: [
          { required: true, message: '请填写门锁命名', trigger: 'blur' },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        lockFeatureCode: { max: 200, message: '不能超过200字', trigger: 'blur' }
      }
    }
  },
  mounted () {
    this.getCampusList()
    this.getTeachBuildTree()
    this.$nextTick(async () => {
      // 需要先获取 buildId, campusId, floorNo 才能加载classRoomList
      await this.getClassRoomList()
      if (this.id) {
        await this.getDataById(this.id, getTeachRoomById)
      }
    })
  },
  methods: {
    addTeachRoom, // 保存api
    // 获取教学楼列表
    getTeachBuildTree () {
      getTeachBuildTree().then((res) => {
        this.teachBuildList = res.data
      })
    },
    // 获取该校区该教学楼该楼层 的教室列表
    async getClassRoomList () {
      this.clearList(this.classRoomList)
      try {
        const _query = {
          buildId: this.formData.buildId,
          campusId: this.formData.campusId,
          floorNo: this.formData.floorNo
        }
        const res = await getClassroomList(_query)
        this.classRoomList = res.data
      } catch (e) {
      }
    },
    handleBuild (val) {
      this.formData.classroomId = ''
      this.getClassRoomList()
    },
    handleCampus (val) {
      this.formData.classroomId = ''
      this.getClassRoomList()
    },
    // 根据楼层获取classRooomList
    handleFloor (val) {
      this.formData.classroomId = ''
      this.getClassRoomList()
    },
    // 获取教室名
    handleClassName (val) {
      this.formData.classroomName = ''
      if (val) {
        this.formData.classroomName = this.$refs.classRoomRef.selectedLabel
      }
    },
    async handleSave () {
      await this.save('门锁', addTeachRoom)
    }
  }
}
</script>
