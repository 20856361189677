<template>
  <table-view v-loading="loading" class="class-room" :show-page="false">
    <template #header>
      <div class="oh" style="height:auto;">
        <el-form :model="tableQuery" inline style="display: inline-block;">
          <el-form-item label="校区">
            <el-select v-model="tableQuery.campusId" @change="treeFilter" clearable filterable size="small">
              <el-option v-for="{campusName, id} in campusList" :key="id" :label="campusName" :value="id" />
            </el-select>
          </el-form-item>
        </el-form>
        <div class="header-button fr">
          <upload-excel :permit-key="['class:room:edit']"
                        download-title="下载导入教室模板"
                        import-title="批量导入教室"
                        import-btn-type="purple"
                        :loading.sync="loading"
                        :api="$http.importExcelHttp.importClassroom"
                        @on-download="$http.exportExcelHttp.exportTeachRoomTemplate()"
                        @on-success="renderTable" />
          <el-button v-permission="['class:room:edit']" size="small" icon="el-icon-plus" type="purple"
                     style="margin-left: 10px;"
                     @click="showDialog()">新增教室
          </el-button>
          <span style="color: #dcdfe6; margin-left: 10px;margin-top: 4px;">|</span>
          <upload-excel :permit-key="['class:room:lock:edit']"
                        download-title="下载导入门锁模板"
                        import-title="批量导入门锁"
                        :loading.sync="loading"
                        :api="$http.importExcelHttp.importLock"
                        @on-download="$http.exportExcelHttp.exportLockTemplate()"
                        @on-success="renderTable" />
        </div>
      </div>
    </template>
    <el-card>
      <el-row>
        <!-- 树形图 -->
        <el-col :span="4">
          <div v-for="item in curTree" :key="item.id">
            <el-tree accordion :data="item.treeItem" node-key="id" @node-click="handleNodeClick"
                     :default-expanded-keys="expandedKeys" />
          </div>
        </el-col>
        <!-- 表格 -->
        <el-col :span="20">
          <el-col class="title">
            <span>{{ tableQuery.buildLabel }}</span>
            <span>第{{ tableQuery.floorNo }}层</span>
          </el-col>
          <el-col>
            <el-table :data="teachRoomList" height="calc(100vh - 280px)" border stripe>
              <el-table-column type="index" label="ID" width="50" />
              <el-table-column prop="classroomName" label="教室" width="80" />
              <el-table-column prop="lockMess" label="锁信息" width="600" />
              <el-table-column prop="remarks" label="备注" />
              <el-table-column label="操作" width="300" fixed="right">
                <template v-slot="{ row }">
                  <el-button v-permission="['class:room:edit']" type="primary" size="mini" @click="showDialog(row)">编辑教室
                  </el-button>
                  <el-button v-permission="['class:room:lock:edit']" type="primary" size="mini"
                             @click="showLockDialog(row)">编辑门锁
                  </el-button>
                  <el-button v-permission="['class:room:del']" size="mini" type="danger"
                             @click="del(row.id,`教室-${row.classroomName}`)">删除教室
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-col>
      </el-row>
    </el-card>
    <!-- 编辑教室 -->
    <add-class-room ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable()" />
    <!-- 编辑门锁 -->
    <detali-lock ref="dialogLockRef" :visible.sync="visibleLockDialog" v-if="visibleLockDialog"
                 @on-close="renderTable()" />
  </table-view>
</template>

<script>
import addClassRoom from './dialog/addClassRoom.vue'
import detaliLock from './dialog/detaliLock.vue'
import { getTeachBuildTree } from '@/api/teachBuild'
import { getCampusList } from '@/api/campus'
import { getClassroomList, delClassroomList } from '@/api/teachClassroom'

export default {
  name: 'teachRoom',
  components: {
    addClassRoom,
    detaliLock
  },
  created () {
    this.getTeachBuildTree()
  },
  data () {
    return {
      visibleDialog: false,
      visibleLockDialog: false,
      tableQuery: {
        campusId: '',
        buildId: null,
        buildLabel: null,
        floorNo: null
      },
      expandedKeys: [],
      teachBuildTree: [],
      curTree: [],
      teachRoomList: [],
      loading: false,
      campusList: []
    }
  },
  methods: {
    // 查询教室列表
    renderTable () {
      getClassroomList(this.tableQuery).then((res) => {
        this.teachRoomList = res.data
      })
    },
    // 获取教学楼的树
    getTeachBuildTree () {
      this.loading = true
      this.getCampusList().then(() => {
        getTeachBuildTree()
          .then((res) => {
            this.teachBuildTree = res.data
            this.teachBuildTree.map((item) => {
              item.children = item.floors
            })
          })
          .then(() => {
            // 初始化数据
            this.initData()
            // 校区选择
            this.treeFilter()
            this.renderTable()
          })
      }).finally(() => {
        this.loading = false
      })
    },
    // 初始化数据
    initData () {
      this.tableQuery = {
        campusId: this.teachBuildTree[0].campusId,
        buildId: this.teachBuildTree[0].id,
        buildLabel: this.teachBuildTree[0].label,
        floorNo: 1
      }
    },
    // 获取校区信息
    getCampusList () {
      return getCampusList({}).then((res) => {
        this.campusList = res.data.list
      })
    },
    // 校区选择
    treeFilter () {
      this.$nextTick(() => {
        this.curTree = this.campusList.filter((item) => {
          return item.id === this.tableQuery.campusId
        })
        if (this.curTree.length) {
          this.curTree[0].treeItem = this.teachBuildTree.filter(({ campusId }) => campusId === this.tableQuery.campusId)

          // 默认展开结点
          if (this.curTree[0].treeItem.length) {
            this.expandedKeys = [this.curTree[0].treeItem[0].id]
          }
        }
      })
    },
    // 新增/编辑 教师
    showDialog (row) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.preTitle = row ? '编辑' : '新增'
        if (row) {
          this.$refs.dialogRef.id = row.id
        } else {
          // 新增时,把 tableQuery当前的参数传给 弹窗
          Object.keys(this.$refs.dialogRef.formData).forEach(key => {
            this.$refs.dialogRef.formData[key] = this.tableQuery[key]
          })
          this.$refs.dialogRef.setInitData()
        }
      })
    },
    // 查看门锁list
    showLockDialog (row) {
      this.visibleLockDialog = true
      this.$nextTick(() => {
        this.$refs.dialogLockRef.preTitle = row ? '编辑' : '新增'
        this.$refs.dialogLockRef.title = row.classroomName
        // 无论新增or编辑,都要传parentInfo,用于 门锁弹窗addTeachRoomLock 的新增功能
        Object.keys(this.$refs.dialogLockRef.parentInfo).forEach(key => {
          this.$refs.dialogLockRef.parentInfo[key] = this.tableQuery[key]
        })
        this.$refs.dialogLockRef.parentInfo.classroomId = row.id
        this.$refs.dialogLockRef.parentInfo.classroomName = row.classroomName
        if (row) {
          // this.$refs.dialogLockRef.tableQuery.classroomId =
          this.$refs.dialogLockRef.id = row.id
        } else {
          this.$refs.dialogLockRef.setInitData()
        }
      })
    },
    // 模块内 删除方法, 未引入table-view-mixins
    del (id, rowName = '') {
      this.$confirm(`<p class="confirm-message">此操作将永久删除 <b class="danger">${rowName}</b>, 是否继续?</p>`, '删除警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          this.loading = true
          try {
            await delClassroomList(id)
            this.$message.success('删除成功!')
            await this.renderTable()
          } catch (e) {
          } finally {
            this.loading = false
          }
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },
    // 点击树节点
    handleNodeClick (data, node) {
      // 只能点击第三层节点
      if (node.level === 2) {
        ({
          campusId: this.tableQuery.campusId,
          id: this.tableQuery.buildId,
          label: this.tableQuery.buildLabel
        } = node.parent.data)
        this.tableQuery.floorNo = Number(data.floorNo)
        this.tableQuery.classroomId = data.id
      }

      this.renderTable()
    }
  }
}
</script>

<style lang="scss">
.class-room {

  .filter {
    margin-top: 22px;
  }

  .title {
    margin: 10px;
    font-weight: 700;
  }
}

</style>
