var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"add-teach-room-dialog",attrs:{"title":(_vm.preTitle + "门锁"),"visible":_vm.show,"close-on-click-modal":false,"before-close":_vm.cancel,"width":"680px"},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("确 定")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"所在教学楼","prop":"buildId"}},[_c('el-select',{attrs:{"disabled":""},on:{"change":_vm.handleBuild},model:{value:(_vm.formData.buildId),callback:function ($$v) {_vm.$set(_vm.formData, "buildId", $$v)},expression:"formData.buildId"}},_vm._l((_vm.teachBuildList),function(ref){
var label = ref.label;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":label,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"所在校区","prop":"campusId"}},[_c('el-select',{attrs:{"disabled":""},on:{"change":_vm.handleCampus},model:{value:(_vm.formData.campusId),callback:function ($$v) {_vm.$set(_vm.formData, "campusId", $$v)},expression:"formData.campusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":campusName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"所在楼层","prop":"floorNo"}},[_c('el-input-number',{attrs:{"min":1,"max":30,"disabled":""},on:{"change":_vm.handleFloor},model:{value:(_vm.formData.floorNo),callback:function ($$v) {_vm.$set(_vm.formData, "floorNo", $$v)},expression:"formData.floorNo"}})],1),_c('el-form-item',{attrs:{"label":"所在教室","prop":"classroomId"}},[_c('el-select',{ref:"classRoomRef",attrs:{"disabled":!_vm.classRoomList.length,"filterable":""},on:{"change":_vm.handleClassName},model:{value:(_vm.formData.classroomId),callback:function ($$v) {_vm.$set(_vm.formData, "classroomId", $$v)},expression:"formData.classroomId"}},_vm._l((_vm.classRoomList),function(ref){
var id = ref.id;
var classroomName = ref.classroomName;
return _c('el-option',{key:id,attrs:{"value":id,"label":classroomName}})}),1)],1),_c('el-form-item',{attrs:{"label":"门锁命名","prop":"roomName"}},[_c('el-input',{model:{value:(_vm.formData.roomName),callback:function ($$v) {_vm.$set(_vm.formData, "roomName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.roomName"}})],1),_c('el-form-item',{attrs:{"label":"绑定锁的设备特征值","prop":"lockFeatureCode"}},[_c('el-input',{model:{value:(_vm.formData.lockFeatureCode),callback:function ($$v) {_vm.$set(_vm.formData, "lockFeatureCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.lockFeatureCode"}})],1),_c('el-form-item',{attrs:{"label":"备注","prop":"remarks"}},[_c('el-input',{model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.remarks"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }