<template>
  <el-dialog
    class="class-room-dialog"
    :title="`${preTitle}教室`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="540px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="所在校区" prop="campusId">
        <el-select v-model="formData.campusId" @change="handleTeachBuildList">
          <el-option
            v-for="{campusName, id} in campusList"
            :label="campusName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所在教学楼" prop="buildId">
        <el-select v-model="formData.buildId" @change="handleFloorList">
          <el-option
            v-for="{label, id} in teachBuildList"
            :label="label"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所在楼层" prop="floorNo">
        <el-select v-model="formData.floorNo">
          <el-option
            v-for="{label,floorNo, id} in floorList"
            :label="label"
            :value="floorNo"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="新增教室命名" prop="classroomName">
        <el-input v-model.trim="formData.classroomName" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type="primary" @click="save('教室', saveClassroomList)">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getTeachBuildTree } from '@/api/teachBuild'
import { saveClassroomList, getClassroomById } from '@/api/teachClassroom'
import dialog from '@/vue/mixins/dialog'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  name: 'addClassRoom',
  mixins: [dialog, campusSchMajorClasses],
  data () {
    return {
      teachBuildList: [], // 当前校区的教学楼 list
      AllTeachBuildList: [], // 所有的教学楼 list
      floorList: [], // 楼层list
      formData: {
        id: null,
        buildId: '',
        campusId: '',
        floorNo: '',
        classroomName: '',
        remarks: ''
      },
      rules: {
        classroomName: this.$store.state.commonValidate.limit20WordsObj
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.getCampusList(),
      this.getTeachBuildTree()
    ])
    await this.$nextTick()
    if (this.id) {
      await this.getDataById(this.id, getClassroomById)
    } else {
      if (!this.formData.campusId) {
        this.$message.error('请求失败,请重试!')
        this.close()
      }
    }
    if (this.formData.campusId) {
      this.handleTeachBuildList(this.formData.campusId)
      if (this.formData.buildId) {
        this.handleFloorList(this.formData.buildId)
      }
      this.setInitData()
    }
  },
  methods: {
    saveClassroomList, // 保存api
    // 获取教学楼列表
    async getTeachBuildTree () {
      try {
        const res = await getTeachBuildTree()
        this.AllTeachBuildList = res.data
      } catch (e) {
      }
    },
    // 根据campusId 过滤教学楼
    handleTeachBuildList (val) {
      if (val) {
        this.teachBuildList = this.AllTeachBuildList.filter(({ campusId }) => campusId === val)
      }
    },
    // 获取选中教学楼的楼层list
    handleFloorList (val) {
      if (val) {
        this.floorList = this.AllTeachBuildList.find(item => item.id === val).floors
      }
    }
  }
}
</script>
